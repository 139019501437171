import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Creators as GerenteCreators } from "../../store/ducks/gerentePlataforma";

import { ObjIsEmpty } from "../../utils/helper";
import { Container, Conteudo } from "./styles";

import Topo from "../topo";
import FiltroContrato from "../filtroContrato";
import Modal from "./tipoModal";
import TipoContrato from "./tipoContrato";

function VincularContratos() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [select, setSelect] = useState([]);
    const [itemSelect, setItemSelect] = useState({});
    const [filter, setFilter] = useState("");
    const [dados, setDados] = useState([]);

    const [dadosTemp, setDadosTemp] = useState([]);

    const [typeAcao, setTypeAcao] = useState("");

    const { dados: data } = useSelector((state) => state.gerentePlataforma);

    const {
        location: { itemselect: url },
    } = history;

    if (!url) {
        history.push("/sistema");
    }

    useEffect(() => {
        if (itemSelect?.label) {
            const type_acao = dados[itemSelect?.label]
                ? dados[itemSelect?.label][0].acao.type
                : "";

            setTypeAcao(type_acao);
            setDadosTemp(
                dados[itemSelect.label] ? dados[itemSelect.label] : []
            );
        } else {
            const type_acao = dados[url?.label]
                ? dados[url?.label][0].acao.type
                : "";
            setTypeAcao(type_acao);
            setDadosTemp(dados[url?.label] ? dados[url?.label] : []);
        }
    }, [dados, url, itemSelect, itemSelect.label]);

    useEffect(() => {
        if (!ObjIsEmpty(data)) {
            const keys = Object.keys(data);

            setSelect(
                keys.map((item, key) => {
                    return { value: key, label: item };
                })
            );

            setDados(data);
        }
    }, [data]);

    useEffect(() => {
        dispatch(GerenteCreators.request());
    }, [dispatch]);

    return (
        <>
            <Container>
                <Conteudo>
                    <Topo />
                    <FiltroContrato
                        select={select}
                        setItemSelect={setItemSelect}
                        placeholder={url?.label}
                        handleSearhChange={(filter) => setFilter(filter)}
                    />
                    <div className="conteudo">
                        {typeAcao ? (
                            <TipoContrato
                                tipo={typeAcao}
                                dados={dadosTemp}
                                filter={filter}
                            />
                        ) : null}
                    </div>
                </Conteudo>
            </Container>
            <Modal tipo={typeAcao} />
        </>
    );
}

export default VincularContratos;
