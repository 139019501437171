import React from "react";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  usePagination,
} from "react-table";

import Vinculo from "../../components/vinculo/seguros";

import { Container } from "./styles";

function Table(props) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: Math.round(window.innerWidth * 0.05),
      width: Math.round(window.innerWidth * 0.07),
      maxWidth: Math.round(window.innerWidth * 0.2),
    }),
    []
  );
  const headerProps = (props, { column }) => getStyles(props, "left");

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "left"
            ? "flex-start"
            : "center",
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns: props.columns,
        data: props.data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: props.pageSize ? props.pageSize : 50,
        },
      },
      useFlexLayout,
      useResizeColumns,
      usePagination
    );

  return (
    <Container>
      <div {...getTableProps()} className="table tabela-modal-adm">
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr header">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);

            const {
              original: { id, status },
            } = row;

            const statusTipo = [
              {
                status_id: 1,
                status_text: "Aprovado",
                class: "aprovado",
                btnTitle: "",
              },
              {
                status_id: 2,
                status_text: "Vinculado",
                class: "aguardando",
                btnTitle: "Alterar Vínculo",
              },
              {
                status_id: 3,
                status_text: "Vincular",
                class: "vinculo",
                btnTitle: "Vínculo",
              },
            ];

            const tipo = statusTipo.filter(
              (item) => item.status_id === status.id
            )[0];

            return (
              <>
                <div {...row.getRowProps()} className="tr body">
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps(cellProps)}
                        className={`td ${cell.value ? "" : "center"}`}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
                <Vinculo
                  id={id}
                  status_vinculo={status}
                  tipo={tipo}
                  dados={row.original}
                  toggle={props.item[0].acao.bind_enable}
                  titleBtn={tipo.btnTitle}
                  seguro={true}
                />
              </>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default Table;
