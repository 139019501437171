import React from "react";

import Button from "../../components/button";
import { Header, Container } from "./styles";

import link from "../../assets/img/sistema/Interface.svg";
import closeImg from "../../assets/img/sistema/close.svg";

function Aviso(props) {
    const { rest } = props;
    return (
        <Container className="vinculo">
            <Header className="vinculo">
                <div className="container-img-text">
                    <img src={link} alt="" />
                    <h3>{rest.title}</h3>
                </div>

                <div
                    className="container-img"
                    onClick={() => {
                        if (props.rest?.closeModal) {
                            props.rest.closeModal();
                        }
                    }}
                >
                    <img src={closeImg} alt="" />
                </div>
            </Header>

            <div className="content alerta">
                <p>{rest.description}</p>

                <div className="container-btns">
                    <Button
                        title="ok"
                        onClick={() => {
                            if (props.rest?.closeModal) {
                                props.rest.closeModal();
                            }
                        }}
                    />
                </div>
            </div>
        </Container>
    );
}

export default Aviso;
