/**
 * Types
 */
export const Types = {
    REQUETS_FUNCIONARIO: "REQUETS_FUNCIONARIO",

    REQUETS_CREATE_FUNCIONARIO: "REQUETS_CREATE_FUNCIONARIO",

    SUCCESS_FUNCIONARIO: "SUCCESS_FUNCIONARIO",
    FAILUIRE_FUNCIONARIO: "FAILUIRE_FUNCIONARIO",

    SUCCESS_FUNCIONARIO_CREATE: "SUCCESS_FUNCIONARIO_CREATE",
    FAILUIRE_FUNCIONARIO_CREATE: "FAILUIRE_FUNCIONARIO_CREATE",

    ADD_DATA: "ADD_DATA",
    GET_DATA: "GET_DATA",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    loading: false,

    error: false,
    error_message: "",
    success: false,

    success_create: false,
    error_create: false,

    data: [],
};

export default function Funcionario(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.REQUETS_FUNCIONARIO:
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
            };

        case Types.REQUETS_CREATE_FUNCIONARIO:
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
            };

        case Types.ADD_DATA:
            return {
                ...state,
                loading: true,
                error_create: false,
                success_create: false,
                data: [...state.data, action.payload],
            };

        case Types.GET_DATA:
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
                data: [...action.payload],
            };

        case Types.SUCCESS_FUNCIONARIO:
            return { ...state, loading: false, error: false, success: true };

        case Types.FAILUIRE_FUNCIONARIO:
            return { ...state, loading: false, error: true, success: false };

        case Types.SUCCESS_FUNCIONARIO_CREATE:
            return {
                ...state,
                loading: false,
                error_create: false,
                success_create: true,
            };

        case Types.FAILUIRE_FUNCIONARIO_CREATE:
            return {
                ...state,
                loading: false,
                error_create: true,
                success_create: false,
                error_message: action.payload,
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    create: (values) => {
        return {
            type: Types.REQUETS_CREATE_FUNCIONARIO,
            payload: values,
        };
    },
    request: (acao_id) => {
        return {
            type: Types.REQUETS_FUNCIONARIO,
            payload: acao_id,
        };
    },
};
