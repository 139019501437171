import { all, takeLatest } from "redux-saga/effects";

import { Types as LoginTypes } from "../ducks/login";
import { Types as EsqueciSenha } from "../ducks/esqueciSenha";
import { Types as UserTypes } from "../ducks/user";

import { Types as VinculoTypes } from "../ducks/vinculo";
import { Types as AprovarTypes } from "../ducks/aprovarContratos";
import { Types as GerenteTypes } from "../ducks/gerentePlataforma";
import { Types as CargaAcaoAdmTypes } from "../ducks/cargaAcaoAdm";
import { Types as FuncionariosTypes } from "../ducks/funcionario";
import { Types as AcaoAdmTypes } from "../ducks/acaoAdm";

import { store } from "./user";
import { login, logout, refresh } from "./login";
import { vinculo, vinculoSeguro, vinculoAcaoMit } from "./vinculo";
import {
    aprovarContrato,
    aprovarContratoAcaoMit,
    contratoAdm,
} from "./aprovarContratos";
import { getContratos } from "./gerentePlataforma";
import {
    storeAcao,
    cargaStore,
    requestCargaAdm,
    requestStatusAcao,
    requestVincularContrato,
    requestAprovarVinculo,
} from "./cargaAcaoAdm";
import { funcionarios, funcionariosStore } from "./funcionarios";
import {
    forgetPassword,
    confirmCode,
    ResetPassword,
    novoCode,
} from "./esqueciSenha";

export default function* rootSaga() {
    yield all([
        takeLatest(UserTypes.STORE_USER, store),

        takeLatest(LoginTypes.REQUEST_LOGIN, login),
        takeLatest(LoginTypes.REQUEST_LOGOUT, logout),
        takeLatest(LoginTypes.REQUEST_REFRESH, refresh),

        takeLatest(EsqueciSenha.REQUEST_ESQUECI_SENHA, forgetPassword),
        takeLatest(EsqueciSenha.REQUEST_NOVO_CODE, novoCode),
        takeLatest(EsqueciSenha.REQUEST_CONFIRM_CODE, confirmCode),
        takeLatest(EsqueciSenha.REQUEST_RESETA_SENHA, ResetPassword),

        takeLatest(VinculoTypes.REQUETS_VINCULO, vinculo),
        takeLatest(VinculoTypes.REQUETS_VINCULO_SEGURO, vinculoSeguro),
        takeLatest(VinculoTypes.REQUETS_VINCULO_ACAO_MIT, vinculoAcaoMit),

        takeLatest(AprovarTypes.REQUETS_APROVAR_CONTRATO, aprovarContrato),
        takeLatest(
            AprovarTypes.REQUETS_APROVAR_CONTRATO_MIT,
            aprovarContratoAcaoMit
        ),
        takeLatest(AprovarTypes.REQUETS_CONTRATO_ADM, contratoAdm),

        takeLatest(GerenteTypes.REQUETS_GERENTE_PLATAFORMA, getContratos),

        takeLatest(AcaoAdmTypes.ACAO_STORE, storeAcao),

        takeLatest(CargaAcaoAdmTypes.STORE_CARGA_ACAO_ADM, cargaStore),
        takeLatest(CargaAcaoAdmTypes.REQUETS_CARGA_ACAO_ADM, requestCargaAdm),
        takeLatest(
            CargaAcaoAdmTypes.REQUEST_STATUS_ACAO_ADM,
            requestStatusAcao
        ),
        takeLatest(
            CargaAcaoAdmTypes.REQUEST_VINCULAR_CONTRATOS,
            requestVincularContrato
        ),
        takeLatest(
            CargaAcaoAdmTypes.REQUEST_APROVAR_CONTRATOS,
            requestAprovarVinculo
        ),

        takeLatest(FuncionariosTypes.REQUETS_FUNCIONARIO, funcionarios),
        takeLatest(
            FuncionariosTypes.REQUETS_CREATE_FUNCIONARIO,
            funcionariosStore
        ),
    ]);
}
