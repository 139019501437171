import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalEditarContratoCreators } from "../../store/ducks/modalEditarContrato";

import { api } from "../../services/api";
import { filtroUsuarioContrato } from "../../utils/helper";

import Table from "./tabelaModalEditarContrato";
import { columnsEditarContratos } from "./columns";

import contratoImg from "../../assets/img/sistema/contrato-laranja.svg";
import fecharImg from "../../assets/img/sistema/close.svg";

import { Modal } from "./styles";

function ModalEditarContrato() {
    const columns = React.useMemo(() => columnsEditarContratos, []);
    const [usuarios, setUsuarios] = useState([]);
    const [filtro, setFiltro] = useState("");
    const arrFilter = filtroUsuarioContrato(usuarios, filtro);
    const dispatch = useDispatch();

    useEffect(() => {
        async function loadUsuarios() {
            const { data } = await api.get("/usuario");
            setUsuarios(data);
        }
        loadUsuarios();
    }, []);

    return (
        <Modal>
            <div className="container-header">
                <div className="title">
                    <img src={contratoImg} alt="" />
                    <h1>Contratos</h1>
                </div>

                <div className="close">
                    <button
                        onClick={() =>
                            dispatch(ModalEditarContratoCreators.close())
                        }
                    >
                        <img src={fecharImg} alt="" />
                    </button>
                </div>
            </div>

            <div className="content">
                <small>Busque um GP para víncular a esse contrato</small>

                <input
                    type="text"
                    className="filtro-gp"
                    placeholder="Digite o login do GP"
                    onChange={(e) => setFiltro(e.target.value)}
                />

                <Table columns={columns} data={arrFilter} />
            </div>
        </Modal>
    );
}

export default ModalEditarContrato;
