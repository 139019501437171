import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTable, useResizeColumns, useFlexLayout } from "react-table";

import { filtroContratosAdm } from "../../../utils/helper";

import Vinculo from "../vinculo/financiamento";

import { TableStyles } from "../styles";

function TableFinanciamento(props) {
  const { filtro, setModalExcluirContrato, setDadosExcluirContrato } = props;

  const { data } = useSelector((state) => state.cargaAcaoAdm);

  const contratos = data.contratosList.reduce((acc, { data }) => {
    if (data) {
      acc.push(...data);
    }
    return acc;
  }, []);

  const arrFiltrado = filtroContratosAdm(contratos, filtro);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: Math.round(window.innerWidth * 0.05),
      width: Math.round(window.innerWidth * 0.07),
      maxWidth: Math.round(window.innerWidth * 0.2),
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "CodRevend",
        accessor: "cod_revend",
      },
      {
        Header: "datCntrt",
        accessor: "datCntl",
      },
      {
        Header: "Grupo Economico",
        accessor: "grupo_economico",
      },
      {
        Header: "CNPJ Cliente",
        accessor: "cnpj",
      },
      {
        Header: "Nome Cliente",
        accessor: "nome_cliente",
      },
      {
        Header: "Marca",
        accessor: "marca",
      },
      {
        Header: "Modelo",
        accessor: "modelo",
      },
      {
        Header: "numCntrt",
        accessor: "numCntl",
      },
      {
        Header: "numpront",
        accessor: "numpront",
      },
      {
        Header: "veicChassi",
        accessor: "veicChassi",
      },
      {
        Header: "nome_fornecedor",
        accessor: "nomeFornecedor",
      },
      {
        Header: "qtdbem",
        accessor: "qtdbem",
      },
    ],
    []
  );

  const headerProps = (props, { column }) => getStyles(props, "left");

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "left"
            ? "flex-start"
            : "center",
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: arrFiltrado,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    );

  return (
    <TableStyles>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr header">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const {
              original: { id, status },
            } = row;

            const statusTipo = [
              {
                status_id: 1,
                status_text: "Aprovado",
                class: "aprovado",
              },
              {
                status_id: 2,
                status_text: "Vinculado",
                class: "aguardando",
              },
              {
                status_id: 3,
                status_text: "Aguardando Vínculo",
                class: "vinculo",
              },
            ];

            const tipo = statusTipo.filter(
              (item) => item.status_id === status.id
            )[0];
            return (
              <>
                <div {...row.getRowProps()} className="tr body">
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps(cellProps)}
                        className={`td ${cell.value ? "" : "center"}`}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
                <Vinculo
                  id={id}
                  tipo={tipo}
                  dados={{
                    ...row.original,
                    setModalExcluirContrato,
                    setDadosExcluirContrato,
                  }}
                />
              </>
            );
          })}
        </div>
      </div>
    </TableStyles>
  );
}

export default TableFinanciamento;
