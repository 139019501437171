import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";
import { Creators as VinculoCreators } from "../../store/ducks/vinculo";
import { tipoObjetoVinculo } from "../../utils/helper";

import Button from "../../components/button";
import { Header, Container } from "./styles";

import link from "../../assets/img/sistema/Interface.svg";
import closeImg from "../../assets/img/sistema/close.svg";

function ConfirmaVinculo(props) {
    const { tipo: tipoContrato } = props;
    const dispatch = useDispatch();
    const { vincular_dados } = useSelector((state) => state.modalVinculo);

    const dados = tipoObjetoVinculo(vincular_dados);

    const { tipo, cpf, nome } = dados;

    function close() {
        dispatch(ModalCreators.modal_confirma_vinculo());
    }

    function vincular() {
        if (tipo === "Vendedor") {
            if (cpf.replace(/[.\-/]/g, "").length > 11) {
                switch (tipoContrato) {
                    case "financiamento":
                        dispatch(
                            VinculoCreators.requestVinculo(vincular_dados, true)
                        );
                        break;

                    case "cartao_mit":
                        dispatch(
                            VinculoCreators.requestVinculoAcaoMit(
                                vincular_dados,
                                true
                            )
                        );
                        break;

                    default:
                        break;
                }
            } else {
                dispatch(ModalCreators.modal_vinculo_gerente());
                dispatch(ModalCreators.modal_confirma_vinculo());
            }
        } else {
            dispatch(ModalCreators.modal_confirma_vinculo());
            dispatch(ModalCreators.modal_vinculo_finaliza());

            switch (tipoContrato) {
                case "financiamento":
                    dispatch(VinculoCreators.requestVinculo(vincular_dados));
                    break;

                case "cartao_mit":
                    dispatch(
                        VinculoCreators.requestVinculoAcaoMit(vincular_dados)
                    );
                    break;

                default:
                    break;
            }

            // dispatch(VinculoCreators.requestVinculo(vincular_dados));
            // dispatch(GerenteCreators.request());
            dispatch(ModalCreators.clear_dados());
        }
    }

    return (
        <Container className="vinculo">
            <Header className="vinculo">
                <div className="container-img-text">
                    <img src={link} alt="" />
                    <h3>Vincular</h3>
                </div>

                <div className="container-img" onClick={() => close()}>
                    <img src={closeImg} alt="" />
                </div>
            </Header>

            <div className="content">
                <p>Confirma o vínculo do {tipo}</p>
                <p>
                    <strong>
                        {nome} - {cpf.length > 11 ? "CNPJ" : "CPF"}: {cpf}
                    </strong>
                </p>
                <p>ao contrato: 234567890</p>

                <div className="container-btns">
                    <Button active={true} title="Não" onClick={() => close()} />
                    <Button title="Sim" onClick={() => vincular()} />
                </div>
            </div>
        </Container>
    );
}

export default ConfirmaVinculo;
