import React, { useMemo } from "react";
import Table from "../tabelas/aprovarContratos";
import { formataDadosTabelaFinanciamento } from "../../utils/helper";
// import { Container } from './styles';

function Financiamento(props) {
    const { dados, tipo } = props;

    const columns = React.useMemo(
        () => [
            {
                Header: "CodRevend",
                accessor: "cod_revend",
            },
            {
                Header: "datCntrt",
                accessor: "dat_cntl",
            },
            {
                Header: "Gerente Plataforma",
                accessor: "gerente_plataforma",
            },
            {
                Header: "Grupo Economico",
                accessor: "grupo_economico",
            },
            {
                Header: "CNPJ Cliente",
                accessor: "cnpj",
            },
            {
                Header: "Nome Cliente",
                accessor: "nome_cliente",
            },
            {
                Header: "Marca",
                accessor: "marca",
            },
            {
                Header: "Modelo",
                accessor: "modelo",
            },
            {
                Header: "numCntrt",
                accessor: "num_cntrt",
            },
            {
                Header: "numpront",
                accessor: "numpront",
            },
            {
                Header: "veicChassi",
                accessor: "veic_chassi",
            },
            {
                Header: "nome_fornecedor",
                accessor: "nome_fornecedor",
            },
            {
                Header: "qtdbem",
                accessor: "qtd_bem",
            },
        ],
        []
    );

    const dadosTabela = useMemo(
        () => formataDadosTabelaFinanciamento(dados),
        [dados]
    );

    return (
        <Table
            columns={columns}
            data={dadosTabela.result}
            item={dados[0].acao.approve_enable}
            acaoType={tipo}
        />
    );
}

export default Financiamento;
