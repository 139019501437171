import { put, call } from "redux-saga/effects";
import { Types as FuncionariosTypes } from "../ducks/funcionario";

import { formataCPF, formataCNPJ } from "../../utils/helper";
import { api } from "../../services/api";

export function* funcionarios({ payload }) {
    try {
        const { data: dados } = yield call(
            api.get,
            `funcionario?acao_id=${payload}`
        );

        const data = dados.map((item) => {
            item.cpf =
                item.documento.length > 11
                    ? formataCNPJ(item.documento)
                    : formataCPF(item.documento);
            return item;
        });

        yield put({
            type: FuncionariosTypes.GET_DATA,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: FuncionariosTypes.FAILUIRE_FUNCIONARIO,
        });
    }
}
export function* funcionariosStore({ payload }) {
    try {
        const { data } = yield call(api.post, `funcionario`, { ...payload });

        data.resource.cpf =
            data.resource.documento.length > 11
                ? formataCNPJ(data.resource.documento)
                : formataCPF(data.resource.documento);

        yield put({
            type: FuncionariosTypes.ADD_DATA,
            payload: data.resource,
        });

        yield put({
            type: FuncionariosTypes.SUCCESS_FUNCIONARIO_CREATE,
        });
    } catch (error) {
        console.log(error.response.data.error);
        yield put({
            type: FuncionariosTypes.FAILUIRE_FUNCIONARIO_CREATE,
            payload: error.response.data.error,
        });
    }
}
