import { combineReducers } from "redux";
import login from "./login";
import user from "./user";
import contratante from "./contratante";
import acao from "./acao";
import acoes from "./acoes";
import temp from "./temp";
import modal from "./modal";
import notificacao from "./notificacao";
import esqueciSenha from "./esqueciSenha";

import modalVinculo from "./modalVinculo";
import modalVinculoSeguro from "./modalVinculoSeguro";
import modalAction from "./modalAction";
import modalEditarContrato from "./modalEditarContrato";
import modalActionContrato from "./modalActionContrato";

import aprovarContratos from "./aprovarContratos";
import gerentePlataforma from "./gerentePlataforma";
import modalAdm from "./modalAdm";
import acaoAdm from "./acaoAdm";
import cargaAcaoAdm from "./cargaAcaoAdm";
import funcionario from "./funcionario";

export default combineReducers({
    login,
    user,
    contratante,
    acao,
    acoes,
    temp,
    modal,
    notificacao,
    esqueciSenha,
    modalVinculo,
    modalAction,
    modalVinculoSeguro,
    modalEditarContrato,
    modalActionContrato,
    aprovarContratos,
    gerentePlataforma,
    modalAdm,
    acaoAdm,
    cargaAcaoAdm,
    funcionario,
});
