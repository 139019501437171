import React from "react";
import { api } from "../../../services/api";

import { useDispatch } from "react-redux";
import { Creators as CargaAcaoAdmCreators } from "../../../store/ducks/cargaAcaoAdm";

import Button from "../../../components/button";
import { Header, Container } from "../../modalVinculo/styles";

import icone from "../../../assets/img/sistema/add-vermelho.svg";
import closeImg from "../../../assets/img/sistema/close.svg";

function ModalConfirmaExclusao(props) {
    const dispatch = useDispatch();

    async function excluir() {
        const { rest } = props;
        const { dados, type, contrato_id } = rest;

        try {
            if (type === "financiamento") {
                await api.delete(`/contrato/${dados.id}`);
            }

            if (type === "seguro") {
                await api.delete(`/produto/${dados.id}`);
            }

            if (type === "cartao_mit") {
                await api.delete(`/cartao/${dados.id}`);
            }

            if (props.rest.onClose) {
                props.rest.onClose();
            }

            dispatch(CargaAcaoAdmCreators.request(contrato_id));
        } catch (error) {}
    }

    return (
        <Container className="vinculo">
            <Header className="vinculo">
                <div className="container-img-text">
                    <img src={icone} alt="" />
                    <h3>Carga</h3>
                </div>

                <div className="container-img">
                    <img src={closeImg} alt="" />
                </div>
            </Header>

            <div className="content">
                <p>Deseja excluir</p>

                <div className="container-btns">
                    <Button
                        active={true}
                        title="Não"
                        onClick={() => {
                            if (props.rest.onClose) {
                                props.rest.onClose();
                            }
                        }}
                    />
                    <Button
                        title="Sim"
                        type="button"
                        onClick={() => excluir()}
                    />
                </div>
            </div>
        </Container>
    );
}

export default ModalConfirmaExclusao;
