import React, { useEffect, memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { persistor } from "../store";
import { api } from "../services/api";

import { errorMenssage, checkTokenExpired } from "../utils/helper";
import { Creators as LoginCreators } from "../store/ducks/login";

function Interceptor() {
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();

    const errorHandler = (error) => {
        const { err, status } = errorMenssage({ ...error });

        switch (status) {
            case 500:
                console.log("500 ->", err);
                break;
            case 404:
                console.log("404 ->", err);
                break;
            case 401:
                persistor.purge();
                window.location.reload();
                break;
            default:
                break;
        }

        return Promise.reject({ ...error });
    };

    const successHandler = useCallback(async (response) => {
        setRefresh(checkTokenExpired());
        return await response;
    }, []);

    useEffect(() => {
        api.interceptors.response.use(
            (response) => successHandler(response),
            (error) => errorHandler(error)
        );

        if (refresh) dispatch(LoginCreators.requestRefresh());
    }, [successHandler, dispatch, refresh]);

    return <React.Fragment />;
}

export default memo(Interceptor);
