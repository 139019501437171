import React from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";

import { Header } from "./styles";
import link from "../../assets/img/sistema/Interface.svg";
import close from "../../assets/img/sistema/close.svg";

function ModalVinculo(props) {
  const { etapa, tipo } = props;
  const dispatch = useDispatch();
  function click() {
    // dispatch(ModalCreators.modal_vincular());
    if (tipo === "vendedor") {
      dispatch(ModalCreators.modal_vinculo_vendedor());
    } else {
      dispatch(ModalCreators.modal_vinculo_gerente());
    }
  }
  return (
    <Header>
      <div className="container-img-text">
        <img src={link} alt="" />
        <h3>Vincular</h3>
      </div>

      <div className="container-etapas">
        <span className={`${etapa === "1" && "active"}`}>01</span>
        <p></p>
        <span className={`${etapa === "2" && "active"}`}>02</span>
      </div>

      <div className="container-img" onClick={() => click()}>
        <img src={close} alt="" />
      </div>
    </Header>
  );
}

export default ModalVinculo;
