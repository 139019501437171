import React, { useEffect, useState } from "react";
import { Creators as ModalCreators } from "../../store/ducks/modalAdm";
import { useDispatch, useSelector } from "react-redux";

import Tabela from "../tabelas/modalTableAdmin";

import { Adm, Topo } from "./styles";

import fechar from "../../assets/img/sistema/close.svg";
import icone from "../../assets/img/sistema/adm-laranja.svg";

function ModalAdm() {
  const dispatch = useDispatch();
  const [dadosFormat, setDadosFormat] = useState([]);
  const { adm } = useSelector((state) => state.acaoAdm);

  function parseAdmData(data) {
    const parsedData = data.map(({ content, regra }) => {
      const parsedContent = JSON.parse(content);

      return {
        ...parsedContent,
        ruleName: regra.name,
        ruleType: regra.type,
      };
    });

    setDadosFormat(parsedData);
  }

  function close() {
    dispatch(ModalCreators.modal_adm());
  }

  const columnsDefault = [
    {
      Header: "Nome",
      accessor: "nome",
    },
    {
      Header: "Login",
      accessor: "login",
    },
    {
      Header: "Nível",
      accessor: "nivel",
    },
  ];

  const columnsAdmLogs = [
    {
      Header: "Nome",
      accessor: "name",
    },
    {
      Header: "Nível",
      accessor: "nivel",
    },
  ];

  useEffect(() => {
    if (adm?.length) {
      if (adm[0].regra) {
        parseAdmData(adm);
      } else {
        setDadosFormat(adm);
      }
    }
  }, [adm]);

  return (
    <Adm>
      <Topo>
        <h3>
          <img src={icone} alt="" />
          Administradores
        </h3>

        <img
          className="close-modal"
          src={fechar}
          alt=""
          onClick={() => close()}
        />
      </Topo>

      <Tabela
        columns={adm?.length && adm[0].regra ? columnsAdmLogs : columnsDefault}
        data={dadosFormat}
        ver={false}
        modal={true}
      />
    </Adm>
  );
}

export default ModalAdm;
