import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";

import { Container } from "./styles";
import Toggle from "../Toggle";
import Profile from "../../assets/img/sistema/profile.svg";
import Sino from "../../assets/img/sistema/sino.svg";
import link from "../../assets/img/sistema/Interface.svg";

function Vinculo(props) {
  const dispatch = useDispatch();

  const {
    tipo,
    titleBtn = "Vincular",
    toggle,
    approved = false,
    id,
    status_vinculo,
    dados,
    acaoType,
  } = props;

  const { nome_vendedor, nome_gerente, status } = dados;

  function click(dados) {
    dispatch(ModalCreators.modal_vinculo_vendedor());
    dispatch(ModalCreators.set_id_modal(dados));
  }

  function handleChange(status) {
    var sendStatus = 2;
    const check = dados?.nome_gerente && dados?.nome_vendedor ? true : false;
    if (status) {
      sendStatus = 1;
    }

    const config = {
      itens: [id],
      sendStatus,
      sem_vinculo: check,
      acaoType,
    };

    if (dados?.nome_gerente && dados?.nome_vendedor) {
      dispatch(ModalActionCreators.open(config));
    } else {
      dispatch(ModalActionCreators.alerta());
    }
  }

  const statusTipo = status.id === 1 ? true : false;

  const classStatus = useMemo(() => {
    let result = "";
    if (status.id === 1) {
      result = "aprovado";
    } else if (status.id === 2) {
      result = "aguardando";
    } else {
      result = "vinculo";
    }
    return result;
  }, [status]);

  return (
    <Container
      className={`${classStatus} ${
        classStatus === "aprovado" ? "btn-olho" : ""
      }`}
    >
      <div className="tipo">
        <img src={Profile} alt="" />
        {dados?.gestor?.id ? (
          <span>
            Gestor: <strong>{dados.gestor.nome}</strong>
          </span>
        ) : (
          <span>
            Vendedor: <strong>{nome_vendedor} </strong>
          </span>
        )}
      </div>
      <div className="tipo">
        {dados?.gestor?.id ? <></> : <img src={Profile} alt="" />}

        {dados?.gestor?.id ? (
          <></>
        ) : (
          <span>
            Gerente / F&I : <strong>{nome_gerente}</strong>
          </span>
        )}
      </div>
      <div className="tipo">
        <img src={Sino} alt="" />
        <span>
          Status:{" "}
          <strong>
            {status.status.charAt(0) + status.status.slice(1).toLowerCase()}
          </strong>
        </span>
      </div>

      {acaoType !== "cartao_mit" && approved && toggle ? (
        <Toggle active={statusTipo} change={handleChange} teste={tipo.class} />
      ) : acaoType !== "cartao_mit" &&
        !approved &&
        toggle &&
        status_vinculo.id !== 1 ? (
        <div className="vinculo">
          <button onClick={() => click({ id, status_vinculo, ...dados })}>
            {" "}
            <img src={link} alt="" /> {titleBtn}
          </button>
        </div>
      ) : null}
    </Container>
  );
}

export default Vinculo;
