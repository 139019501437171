import styled from "styled-components";
export const Container = styled.div`
    background: #ffffff;
    border-radius: 8px;
    width: 859px;
    overflow: hidden;
    &.finalizar {
        width: 380px;
        height: 317px;
        padding-top: 56px;
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        text-align: center;
        &.alerta {
            width: auto;
            height: auto;
            padding: 25px;
            .content {
                width: auto;
            }
            ul {
                margin: 30px 0px;
                max-height: 500px;
                overflow-y: scroll;
                overflow-x: hidden;
                text-align: left;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar:vertical {
                    width: 5px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(89, 92, 118, 0.5);
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(255, 255, 255, 0.1);
                }
                li {
                    white-space: nowrap;
                }
                strong {
                    font-weight: bold;
                }
            }
            .content {
                h3 {
                    width: 100%;
                    margin-bottom: 16px;
                }

                p {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 100%;
                    color: #000;
                    margin-bottom: 17px;
                }
            }
        }

        &.generico {
            height: auto;
            padding-top: 25px;
            .content {
                h3 {
                    width: 100%;
                    margin-bottom: 16px;
                }

                p {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 100%;
                    color: #000;
                    margin-bottom: 17px;
                }
            }
        }

        img {
            display: block;
            margin: 0 auto;
            margin-bottom: 11px;
        }
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 130%;
            text-align: center;
            color: #000000;
            width: 290px;
            margin: 0 auto;
            margin-bottom: 39px;
        }
    }
    &.vinculo {
        width: 380px;
        .content {
            width: 100%;
            padding: 26px 42px 56px 32px;
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 100%;
                color: #4a4a4a;
                margin-bottom: 17px;
                &:last-child {
                    margin-bottom: 0px;
                }
                strong {
                    font-weight: bold;
                }
            }

            .container-btns {
                button {
                    width: 125px;
                    margin-right: 13px;
                }
            }
        }
    }
    .content {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 25px;
    }
`;

export const Header = styled.div`
    height: 84px;
    padding: 0px 55px 0px 42px;
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
    &.vinculo {
        padding: 0px 42px 0px 32px;
        justify-content: space-between;
    }
    .container-img-text {
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 100%;
            color: #000000;
        }
    }

    .container-etapas {
        display: flex;
        align-items: center;
        margin-left: auto;
        p {
            width: 114px;
            background-color: #dedede;
            height: 1px;
            margin: 0px 10px;
        }
        span {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
            color: #c4c4c4;
            transition: all 0.5s;
            &.active {
                color: #ff9029;
                transition: all 0.5s;
            }
        }
    }

    .container-img {
        cursor: pointer;
        margin-left: 57px;
    }
`;

export const FiltroStyles = styled.div`
    padding-top: 33px;
    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 100%;
        color: #4a4a4a;
        margin-bottom: 14px;
    }
    .container-input {
        margin-bottom: 14px;
        input {
            border: 1.5px solid #e0e0e0;
            border-radius: 4px;
            width: 100%;
            height: 54px;

            font-style: normal;
            font-weight: normal;
            letter-spacing: normal;
            font-size: 16px;
            line-height: 100%;
            color: #666666;
            background-color: transparent;
            &::placeholder {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 100%;
                color: #666666;
            }
        }
    }
`;

export const ListaStyles = styled.div`
    ul {
        width: 100%;
        height: 200px;
        overflow: auto;
        margin-bottom: 20px;
        li {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(2, 1fr);
            background: #f8f8f8;
            border-radius: 4px;
            align-items: center;
            width: 100%;
            height: 47px;
            margin-bottom: 7px;
            padding-left: 16px;
            padding-right: 16px;
            transition: background 0.5s;
            cursor: pointer;
            &:hover {
                background: #ffe9dd;
                transition: background 0.5s;
            }

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 100%;
                color: #4a4a4a;
                margin-left: 40px;
                &:first-child {
                    font-weight: bold;
                    margin-left: 0px;
                }

                &:last-child {
                    margin-left: 70px;
                }
            }
        }
    }
`;

export const ToggleFilterStyles = styled.div`
    .container-toggle {
        position: relative;
        &.seguro {
            h2 {
                margin-bottom: 16px;
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                button {
                    background-color: transparent;
                }
            }
        }

        .container-list {
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.5s;
            &.active {
                position: static;
                opacity: 1;
                pointer-events: all;
                transition: all 0.5s;
            }
        }

        .container-result {
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.5s;
            &.active {
                position: static;
                opacity: 1;
                pointer-events: all;
                transition: all 0.5s;
            }

            .card-vinculo {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 4px;
                border: 1.5px solid #e0e0e0;
                align-items: center;
                width: 100%;
                height: 47px;
                margin-bottom: 7px;
                padding-left: 16px;
                padding-right: 16px;
                transition: background 0.5s;
                cursor: pointer;
                margin-bottom: 14px;
                button {
                    width: auto;
                    height: auto;
                    background-color: transparent;
                    border: none;
                }
                span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 100%;
                    color: #4a4a4a;
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }

            .container-btns {
                button {
                    width: fit-content;
                    padding: 0 32px;
                    &:first-child {
                        margin-right: 16px;
                    }
                }
            }
        }

        .container-funcionarios {
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.5s;
            .btns-add-funcionario {
                button {
                    &:last-child {
                        margin-left: 20px;
                    }
                }
            }
            &.active {
                position: static;
                opacity: 1;
                pointer-events: all;
                transition: all 0.5s;
            }
        }
    }
`;
