/**
 * Types
 */
export const Types = {
    OPEN_MODAL_MODAL_ACTION: "OPEN_MODAL_MODAL_ACTION",
    OPEN_MODAL_SEM_VINCULO: "OPEN_MODAL_SEM_VINCULO",
    CLOSE_MODAL_MODAL_ACTION: "CLOSE_MODAL_MODAL_ACTION",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    modal_action: false,
    modal_sem_vinculo: false,
    dados: {},
};

export default function ModalAction(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.OPEN_MODAL_MODAL_ACTION:
            return {
                ...state,
                modal_action: true,
                dados: action.payload,
            };

        case Types.OPEN_MODAL_SEM_VINCULO:
            return {
                ...state,
                modal_sem_vinculo: true,
                dados: action.payload,
            };

        case Types.CLOSE_MODAL_MODAL_ACTION:
            return {
                ...state,
                modal_action: false,
                modal_sem_vinculo: false,
                dados: {},
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    open: (dados) => {
        return { type: Types.OPEN_MODAL_MODAL_ACTION, payload: dados };
    },

    alerta: () => {
        return { type: Types.OPEN_MODAL_SEM_VINCULO };
    },

    close: () => {
        return { type: Types.CLOSE_MODAL_MODAL_ACTION };
    },
};
