import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { Container } from "./styles";

import { Creators as LoginCreators } from "../../store/ducks/login";

import logoTopoImg from "../../assets/img/sistema/logo-topo.svg";

function Topo() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { name } = useSelector((state) => state.user);

    return (
        <Container>
            <div className="container-logo">
                <Link to="/sistema">
                    <img src={logoTopoImg} alt="" />
                </Link>
            </div>
            <div className="container-user">
                <p>Olá, {name}</p>
                <p
                    onClick={() =>
                        dispatch(LoginCreators.requestLogout(history))
                    }
                >
                    Sair
                </p>
            </div>
        </Container>
    );
}

export default Topo;
