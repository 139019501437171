import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useDispatch } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acaoAdm";

import {
  FormInitialState,
  validationCriacaoAcao,
} from "../../utils/configForm";
import Select from "../../components/SelectCustom";
import Buttom from "../../components/button";
import InputCalendar from "../../components/inputCalendar";

import closeImg from "../../assets/img/sistema/close.svg";
import plusImg from "../../assets/img/sistema/plus.svg";

import { NovaAcao } from "./styles";

function FormAcao() {
  const dispatch = useDispatch();
  const options = [
    { value: 1, label: "Seguro" },
    { value: 2, label: "Financiamento" },
    { value: 3, label: "cartao_mit" },
    { value: 4, label: "speed" },
  ];

  return (
    <NovaAcao>
      <div className="container-header">
        <p>
          <img src={plusImg} alt="" /> Nova ação
        </p>
        <button onClick={() => dispatch(AcaoCreators.open_modal_store_acao())}>
          <img src={closeImg} alt="" />
        </button>
      </div>

      <div className="container-acao">
        <Formik
          enableReinitialize
          initialValues={FormInitialState.criarAcao}
          validationSchema={validationCriacaoAcao}
          onSubmit={(value, actions) => {
            dispatch(AcaoCreators.store(value));
          }}
        >
          {(props) => {
            const { setFieldValue } = props;

            return (
              <Form>
                <Select
                  id={1}
                  options={options}
                  label="Tipo da Ação"
                  change={({ value, label }) =>
                    setFieldValue("type", label.toLowerCase())
                  }
                />
                <ErrorMessage component="span" name="type" />

                <div className="container-input">
                  <Field type="text" name="nome" placeholder="Nome da Ação" />
                  <ErrorMessage component="span" name="nome" />
                </div>

                <InputCalendar
                  label="Data inicio"
                  onChange={(change) => setFieldValue("start_period", change)}
                />

                <InputCalendar
                  label="Data Termino"
                  onChange={(change) => setFieldValue("end_period", change)}
                />

                <Buttom active={true} title="ENVIAR" />
              </Form>
            );
          }}
        </Formik>
      </div>
    </NovaAcao>
  );
}

export default FormAcao;
