import React from "react";

import { Container } from "./styles";

function Toggle(props) {
    const { active, change = () => {}, title = "Aprovar" } = props;

    function handleClick() {
        change(!active);
    }

    return (
        <Container>
            <div
                onClick={() => handleClick()}
                className={`${active === true ? "active" : ""} toggle`}
            >
                <span></span>
            </div>

            <p>{title}</p>
        </Container>
    );
}

export default Toggle;
