import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ObjIsEmpty } from "../../utils/helper";

import { Container } from "./styles";
import SelectCustom from "../../components/SelectCustom";
import lupa from "../../assets/img/sistema/lupa.svg";
import contrato from "../../assets/img/sistema/contrato.svg";

const options = [
    { value: 1, label: "Ação 1" },
    { value: 2, label: "Ação 2" },
    { value: 3, label: "Ação 3" },
];

function FiltroContrato(props) {
    const {
        select,
        setItemSelect = () => {},
        placeholder,
        handleSearhChange,
    } = props;

    const history = useHistory();

    const {
        location: { itemselect: url },
    } = history;

    const { dados } = useSelector((state) => state.gerentePlataforma);

    const [selectedItem, setSelectedItem] = useState();

    const { countWainting } = useMemo(() => {
        if (!dados) return 0;

        const result = ObjIsEmpty(selectedItem ?? {})
            ? dados[url?.label]
                ? dados[url?.label]
                : []
            : dados[selectedItem.label]
            ? dados[selectedItem.label]
            : [];

        const countWainting = result
            .filter((item) => item.status.id === 1 || item.status.id === 2)
            .reduce((accumulator) => accumulator + 1, 0);

        return { countWainting };
    }, [dados, url, selectedItem]);

    return (
        <Container>
            <div className="container-select">
                <SelectCustom
                    label={placeholder}
                    options={select.length ? select : options}
                    change={(value) => {
                        setSelectedItem(value);
                        setItemSelect(value);
                    }}
                />
            </div>

            <div className="container-input">
                <input
                    type="text"
                    placeholder="Busca"
                    onChange={(event) =>
                        handleSearhChange(event.currentTarget.value)
                    }
                />
                <img src={lupa} alt="" />
            </div>

            <div className="container-contratos">
                <img src={contrato} alt="" />
                <p>Contratos vinculados - {countWainting} </p>
            </div>
        </Container>
    );
}

export default FiltroContrato;
