import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { nomeArq } from "../../utils/helper";

function InputFile(props) {
    const { data } = useSelector((state) => state.acao);
    const { nome_arquivo } = data;

    const { field, form } = props;
    const [arquivo, setArquivo] = useState(nome_arquivo);

    useEffect(() => {
        if (!field.value) {
            setArquivo("");
        }
    }, [field.value]);

    function handleFile(arq) {
        if (arq) {
            form.setFieldValue(field.name, arq);
            setArquivo(arq.name);
        }
    }

    return (
        <div className="container-form">
            <label
                htmlFor="file"
                className={arquivo ? "custom-file nome-arquivo" : "custom-file"}
            >
                <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="file"
                    onChange={(event) => {
                        handleFile(event.target.files[0]);
                    }}
                />

                {arquivo
                    ? arquivo.length > 25
                        ? nomeArq(arquivo, 25)
                        : arquivo
                    : "Upload do Arquivo"}
            </label>

            {/* {arquivo ? (
        <img src={fechar} onClick={() => removeFile()} alt="" />
      ) : (
        <img src={upload} alt="" />
      )} */}
            <button type="button" className="seleciona-arquivo">
                Selecionar arquivo
            </button>
        </div>
    );
}

export default InputFile;
